<template>
  <v-container style="padding-top: 10vh; padding-bottom: 20vh">
    <v-row class="text-center p-0 m-0" justify="center" align="center">
      <v-col cols="6" md="2" sm="4" align-self="center" class="pe-1">
        <!-- <v-card
          class="pa-2 category-footer"
          flat
          @click="$router.push('/shopping-guide')"
        >
           {{$t("message.shopping-guide")}}
        </v-card> -->
        <v-card
          class="pa-4 category-footer"
          flat
          @click.prevent="goShoppingTerms"
        >
          <!-- @click="$router.push('/shopping-terms')" -->
          {{ $t("message.term-of-use") }}
        </v-card>
        <v-card
          class="pa-4 mt-2 category-footer"
          flat
          @click.prevent="goTajimiInfo"
        >
          <span style="font-size: 14px">{{ $t("message.label-info") }}</span>
        </v-card>
      </v-col>
      <v-col cols="6" md="2" sm="4" align-self="center" class="ps-1">
        <v-card
          class="pa-4 category-footer"
          flat
          href="https://tajimingo.com"
          target="_blank"
        >
          {{ $t("message.btn-about-tajimingo") }}
        </v-card>
        <v-card
          class="pa-4 mt-2 category-footer"
          flat
          @click="$router.push('/contact')"
        >
          {{ $t("message.contactus") }}
        </v-card>
      </v-col>
    </v-row>
    <!-- shoptest.tajimingo.com -->
    <!-- <div :class="!currentRouteName ? 'title-tajimiInfo': 'title-display'">
      <p style="font-size: 14px">
        {{ $t("message.label-tajimiInfo") }}
        <span class="pink--text" @click.prevent="$router.push('/tajimi-info')">
          {{ $t("message.click") }}
        </span>
      </p>
    </div>
    -->
    <v-row class="p-0 m-0 row-class">
      <v-col cols="6" md="2" sm="4" align-self="center" class="pe-1" style="">
        <v-card class="pa-4 category-footer" flat @click="goPrivacyPolicy">
          {{ $t("message.title-privacy-policy") }}
        </v-card>
      </v-col>
      <v-col cols="6" md="2" sm="4" class="pe-1">
        <v-card
          style="margin-left: -8px"
          class="pa-4 category-footer"
          flat
          @click="goPrivacyData"
        >
          個人情報の取扱
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CategoryFooter",
  computed: {
    currentRouteName() {
      let currentRoute = this.$route.name;
      if (currentRoute == "TajimiInfo") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    goShoppingTerms() {
      this.$router.push("/shopping-terms").catch(() => {});

      this.$gtag.event("shopping-terms-click", {
        event_category: "Documentation",
        event_label: "Shopping Terms",
      });
    },
    goPrivacyPolicy() {
      this.$router.push("/privacy-policy").catch(() => {});

      this.$gtag.event("privacy-policy-click", {
        event_category: "Documentation",
        event_label: "Privacy Policy",
      });
    },
    goPrivacyData() {
      this.$router.push("/privacy-data").catch(() => {});

      this.$gtag.event("privacy-data-click", {
        event_category: "Documentation",
        event_label: "Privacy Data",
      });
    },
    goTajimiInfo() {
      this.$router.push("/tajimi-info");
      this.$gtag.event("tajimi-info-click", {
        event_category: "Documentation",
        event_label: "Tajimi Info",
      });
    },
  },
};
</script>

<style>
.row-class {
  margin-top: -16px !important;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
@media (max-width: 414px) {
  .row-class {
    justify-content: start;
    align-items: start;
    text-align: center;
  }
}

.title-tajimiInfo {
  text-align: start;
  margin-top: 10px;
  margin-bottom: -10px;
}
.title-display {
  display: none;
}
.category-footer {
  color: white !important;
  background-color: #707070 !important;
  width: 100%;
  height: 7vh;
}
</style>
