<template>
  <div>
    <div>
      <v-container class="grey lighten-5">
        <v-row class="text-center" no-gutters justify="center" align="center">
          <v-col  v-for="list in links" :key="list.i">
            <v-card
              class="pa-2 pl-2 d-flex justify-center align-center"
              flat
              style=" border: 1px solid black;"
              @click="handleRoute(list.route)"
            >
              <v-icon size="14" class="hide_icon" color="black">{{ list.icon }}</v-icon>
              <span style="font-size: 10px">{{ list.text }}</span>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div style="margin-top: -13px;">
      <v-divider style="fontWeight: 10px; background-color: black;"></v-divider>
    </div>
  </div>
</template>

<script>
import {
  mdiChevronRight,
  mdiMagnify,
  mdiCart,
  mdiHeartOutline,
  mdiStore,
} from "@mdi/js";
export default {
  name: "HeaderNavigasi",
  props: {
    link: Array,
  },
  data(){
    return {
      id : this.$router.history.current.params.id
    }
  },
  computed: {
    links() {
      if (this.id == undefined || this.$route.name == 'OrderDetail') {
        return [
          {
            icon: mdiMagnify,
            text: this.$t("message.route-search"),
            route: "/category-top",
          },
          {
            icon: mdiHeartOutline,
            text: this.$t("message.route-favorite"),
            route: "/wishlist",
          },
          {
            icon: mdiCart,
            text: this.$t("message.route-history"),
            route: "/order-history",
          },
          {
            icon: mdiStore,
            text: this.$t("message.route-store"),
            route: "/store",
          },
        ];
      } else {
        return this.link
      }
    },
  },
  methods: {
    handleRoute(listRoute) {
      if (listRoute == this.$router.history.current.path) {
        return;
      } else {
        this.$router.push(listRoute);
      }
    },
  },
  created() {
  },
};
</script>

<style>
@media only screen and (max-width: 330px) {
  .hide_icon {
    display: none !important;
  }
}
</style>
