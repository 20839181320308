<template>
  <div>
    <!-- <div v-if="isLogin == 1" class="mx-auto justify-center text-center mb-1">
            <router-link to="/items?search=comecomedeliverygo" style="text-decoration: none">
                <v-img
                contain
                :src="banner_comecome1"
                max-height="300px"
                >
                </v-img>
            </router-link>
        </div>
        <div v-else class="mx-auto justify-center text-center mb-1">
            <router-link to="/tempRegister" style="text-decoration: none">
                <v-img
                contain
                :src="banner_202208kaki"
                max-height="300px"
            >
            </v-img>
        </div> -->
        <!-- come come delivery go -->
        <template v-if="isComeComeDeliveryGo">
            <div v-if="isLogin == 1" class="mx-auto justify-center text-center mb-1">
                <!-- <router-link to="/items?search=comecomedeliverygo" style="text-decoration: none"> -->
                    <v-img
                    contain
                    :src="banner_comecome1"
                    max-height="300px"
                    >
                    </v-img>
                <!-- </router-link> -->
            </div>
            <div v-else class="mx-auto justify-center text-center mb-1">
                <router-link to="/tempRegister" style="text-decoration: none">
                    <v-img
                    contain
                    :src="banner_comecome0"
                    max-height="300px"
                    >
                    </v-img>
                </router-link>
            </div>
            <div class="mx-auto justify-center text-center">
                <a href="https://www.city.tajimi.lg.jp/kanko/comedeligo.html" target="_blank" rel="noopener noreferrer" style="text-decoration: none">
                    <v-img
                    contain
                    :src="banner_comecome9"
                    max-height="100px"
                    >
                    </v-img>
                </a>
            </div>
        </template>
        <!-- come come delivery go -->
    <template v-if="data_array.length">
      <div v-if="is_loading">
        <SkeletonBanner />
      </div>
      <div v-else>
        <!-- shop info -->
        <div v-if="show_arrow === true">
          <div class="mx-auto justify-center text-center mt-2">
            <v-carousel
              cycle
              interval="4000"
              hide-delimiters
              hide-delimiter-background
              :show-arrows="false"
              contain
              height="auto"
              max-height="300px"
            >
              <v-carousel-item
                v-for="(slideitem, i) in data_array"
                :key="i"
                :to="slideitem.bannerlink"
                style="text-decoration: none"
                contain
                height="auto"
                max-height="300px"
                eager
              >
                <v-img
                  :src="slideitem.bannerimage"
                  contain
                  height="auto"
                  max-height="300px"
                  eager
                >
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </div>
        </div>
        <!-- Tajimingo info -->
        <div v-else>
          <div class="mx-auto justify-center text-center mt-2">
            <v-carousel
              cycle
              interval="4000"
              hide-delimiters
              hide-delimiter-background
              :show-arrows="false"
              contain
              height="auto"
              max-height="300px"
            >
              <v-carousel-item
                v-for="(slideitem, i) in data_array"
                :key="i"
                contain
                height="auto"
                max-height="300px"
                eager
              >
                <v-img
                  :src="slideitem.bannerimage"
                  contain
                  height="auto"
                  max-height="300px"
                  eager
                >
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SkeletonBanner from "../components/skeleton/SkeletonBanner.vue";

export default {
  name: "Banner",
  components: {
    SkeletonBanner,
  },
  data() {
    return {
      data_array: this.data_slide,
    };
    // return {
    //   banner_comecome0: require("../assets/banner_comecome0.jpg"),
    //   banner_comecome1: require("../assets/banner_comecome1.jpg"),
    //   banner_comecome9: require("../assets/banner_comecome9.png"),
    //   banner_campaign202203a: require("../assets/banner_campaign202203a.png"),
    //   banner_campaign202203b: require("../assets/banner_campaign202203b.png"),
    //   banner_marunaka: require("../assets/banner_marunaka.png"),
    //   banner_hitotemaya: require("../assets/banner_hitotemaya.png"),
    //   banner_food: require("../assets/banner_food.png"),
    //   shopslideitems: [
    //     {
    //         bannerimage:require("../assets/banner_tamanoya_20220324.jpg"),
    //         bannerlink: '/store/9'
    //     },
    //     {
    //         bannerimage:require("../assets/banner_tobundo_20220315.jpg"),
    //         bannerlink: '/store/8'
    //     },
    //     {
    //         bannerimage:require("../assets/banner_hitotemaya_20220316.jpg"),
    //         bannerlink: '/store/7'
    //     },
    //     {
    //         bannerimage:require("../assets/banner_marunaka_20220324.jpg"),
    //         bannerlink: '/store/6'
    //     },
    //     {
    //         bannerimage:require("../assets/banner_oomatsu_20220330.jpg"),
    //         bannerlink: '/store/1'
    //     },
    //   ],
    //   tajimingoslideitems: [
    //     {
    //       bannerimage: require("../assets/banner_campaign202204_a.jpg"),
    //     },
    //     {
    //       bannerimage: require("../assets/banner_campaign202204_b.jpg"),
    //     },
    //     {
    //       bannerimage: require("../assets/banner_campaign202204_a.jpg"),
    //     },
    //     {
    //       bannerimage: require("../assets/banner_campaign202204_b.jpg"),
    //     },
    //   ],
    // };
  },
  props: {
    show_arrow: Boolean,
    data_slide: Array,
    is_loading: Boolean,
  },
  computed: {
    isLogin() {
      return $cookies.get("isLogin");
    },
    // come come delivery go
    isComeComeDeliveryGo() {
        return false;
    },
  },
};
</script>

<style></style>
