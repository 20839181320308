<template>
  <div>
    <v-skeleton-loader
      class=""
      v-bind="attrs"
      type="card-avatar"
    ></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  data: () => ({
    attrs: {
      height: 90,
      width: 90,
      class: "mb-1",
      boilerplate: false,
      elevation: 0,
    },
  }),
};
</script>

<style></style>
