<template>
  <v-skeleton-loader class="mx-3 " v-bind="attrs" type="table-heading, divider, list-item-three-line"></v-skeleton-loader>
</template>

<script>
export default {
  name: "SkeletonCategory",
  data: () => ({
    attrs: {
      class: "mb-3",
      boilerplate: true,
      elevation: 0,
    },
  }),
};
</script>

<style></style>
