<template>
<!-- <v-card class="d-flex flex-row mb-1 mt-0 pt-0 flex-wrap" flat tile> -->
  <v-card class="d-flex flex-row mb-1 pt-0" flat tile>
    <div v-if="user !==''">
      <v-card class="pe-2 pt-2 pb-2" flat>
        {{ user }}
        <span style="font-size:85%;margin-left:8px;margin-right:8px;">様</span>
        <v-btn
          depressed
          color="black"
          dark
          small
          style="text-transform: none;"
          @click.prevent="toMyPage"
        >
          {{ $t("message.my-page") }}
        </v-btn>
      </v-card>
    </div>
    <div v-else class="pe-2 pt-2 pb-2">
        <v-btn
          depressed
          color="black"
          dark
          small
          style="text-transform: none;"
          @click.prevent="toMyPage"
        >
          {{ $t("message.label-login") }}
        </v-btn>
    </div>
    <!-- <v-card-text style="width: 100%;color: #ff0090;">
      <div class="body-1 mb-2">Tajimingo をご利用の皆様へご案内</div>
      <div class="body-2 mb-1">2021年12月28日（火）から2022年1月5日（水）まで、配送業務をお休みさせていただきます。</div>
      <div class="body-2 mb-1">ご注文の際は必ず配送日をご確認のうえ、ご購入いただきますようお願い申し上げます。</div>
    </v-card-text> -->
  </v-card>
</template>

<script>
export default {
  name: "User",
  data() {
    return {
      user: ""
    };
  },
  created() {
    this.checkLogin();
  },
  computed: {
    // userLogin() {
    //   return localStorage.getItem("name_user")
    // }
  },
  methods: {
    toMyPage() {
      let checkCookies = $cookies.get("isLogin");

      if (checkCookies == 1) {
        this.$router.push("/account");
      } else {
        this.$router.push("/login");
      }
    },
    checkLogin() {
      let checkCookies = $cookies.get("isLogin");
      if (checkCookies == 1) {
        this.user = localStorage.getItem("name_user");
      } else {
        this.user = localStorage.removeItem("name_user");
        this.user = "";
      }
    },
  },

};
</script>

<style></style>
